import request from '@/utils/request';

/**查询角色列表
 * @param {Object} data
 */
export function getRoleList(data) {
  return request({
    url: '/role/getList',
    method: 'post',
    data
  });
}

/**
 * @param {Object} data  保存
 */
export function saveRole(data) {
  return request({
    url: '/role/save',
    method: 'post',
    data
  });
}

/**删除
 * @param {Object} data
 */
export function delRole(data) {
  return request({
    url: '/role/del',
    method: 'post',
    data
  });
}

/**
 * @param {Object} data分配角色菜单
 */
export function assignedRoleMenu(data) {
  return request({
    url: '/role/assignedRoleMenu',
    method: 'post',
    data
  });
}

/**
 * 查询角色菜单
 * @param {Object} data
 */
export function getRoleMenuList(data) {
  return request({
    url: '/role/getRoleMenuList',
    method: 'post',
    data
  });
}
