<template>
  <div class="container">
    <div class="handle-box" style="float: left;">
      <el-button icon="el-icon-refresh" @click="onRefresh">刷新</el-button>
      <el-button type="success" icon="el-icon-plus" @click="onAdd">
        添加
      </el-button>
      <el-button type="danger" icon="el-icon-delete" @click="delAll">
        批量删除
      </el-button>
    </div>
    <div style="float: right;">
      <el-form
        ref="queryForm"
        :model="query"
        :inline="true"
        label-width="120px"
      >
        <el-form-item prop="infoId">
          <el-select
            v-model="query.infoId"
            placeholder="请选择电影名称"
            clearable
          >
            <el-option
              v-for="item in movieOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="playDate">
          <el-date-picker
            v-model="query.playDate"
            clearable
            type="date"
            align="left"
            placeholder="请选择放映日期"
            value-format="yyyy-MM-dd"
            :picker-options="pickerOptions"
          ></el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="search">
            搜索
          </el-button>
          <el-button icon="el-icon-reset" @click="resetForm('queryForm')">
            重置
          </el-button>
        </el-form-item>
      </el-form>
    </div>
    <div style="clear: both;"></div>

    <!--数据表格-->
    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="正在加载 ..."
      border
      fit
      highlight-current-row
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="50" />
      <el-table-column label="电影名称" prop="name" />
      <el-table-column label="放映日期" prop="playDate" sortable />
      <el-table-column label="放映场次或时间" prop="playTime" sortable>
        <template slot-scope="scope">
          <i class="el-icon-time"></i>
          <span style="margin-left: 10px">
            <b>{{ scope.row.playTime }}</b>
          </span>
        </template>
      </el-table-column>
      <el-table-column label="预留座位数(默认为0不预留)" prop="nums" />
      <el-table-column label="操作" width="280" align="center">
        <template slot-scope="scope">
          <el-button type="success" size="small" @click="onEdit(scope.row)">
            编辑
          </el-button>
          <el-button type="danger" size="small" @click="onDel(scope.row)">
            删除
          </el-button>
          <el-button
            type="primary"
            size="small"
            :disabled="true"
            @click="sendMsgDialog(scope.row)"
          >
            短信通知(未开通)
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination
        :current-page="pageInfo.startPage"
        :page-sizes="[10, 20, 30, 50, 100, 200]"
        :page-size="pageInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pageInfo.total"
        @size-change="onSizeChange"
        @current-change="onPageChange"
      />
    </div>

    <!-- 编辑信息 -->
    <el-dialog :title="titleName" width="50%" :visible.sync="selectDlgShow">
      <div
        v-loading="dlgLoading"
        class="dialog-body"
        element-loading-text="正在保存..."
      >
        <el-form ref="ruleForm" :model="form" label-width="120px">
          <el-form-item label="电影名称" prop="infoId" required>
            <el-select
              v-model="form.infoId"
              placeholder="请选择电影名称"
              clearable
            >
              <el-option
                v-for="item in movieOptions"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="放映日期" prop="playDate" required>
            <el-date-picker
              v-model="form.playDate"
              clearable
              align="right"
              type="date"
              placeholder="请选择放映日期"
              value-format="yyyy-MM-dd"
              format="yyyy-MM-dd"
              :picker-options="pickerOptions"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="放映时间" prop="playTime" required>
            <el-time-picker
              v-model="form.playTime"
              clearable
              :picker-options="{ selectableRange: '00:00:00 - 23:59:59' }"
              format="HH:mm"
              value-format="HH:mm"
              placeholder="请选择放映时间"
            ></el-time-picker>
          </el-form-item>
          <el-form-item label="预留座位数" prop="nums" required>
            <el-input-number
              v-model="form.nums"
              :min="0"
              :max="200"
              clearable
            ></el-input-number>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="selectDlgShow = false">关 闭</el-button>
        <el-button plain @click="resetForm('ruleForm')">重 置</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')">
          保存
        </el-button>
      </div>
    </el-dialog>

    <!-- 短信发送 -->
    <el-dialog title="短信发送" width="40%" :visible.sync="smsDialog">
      <div
        v-loading="smsLoading"
        class="dialog-body"
        element-loading-text="正在发送..."
      >
        <el-form :model="smsForm" label-width="120px">
          <el-form-item label="用户身份角色" required>
            <el-checkbox
              v-model="checkAll"
              :indeterminate="isIndeterminate"
              @change="handleCheckAllChange"
            >
              全选
            </el-checkbox>
            <div style="margin: 15px 0;"></div>
            <el-checkbox-group
              v-model="checkRoleList"
              @change="handleCheckedRoleChange"
            >
              <el-checkbox
                v-for="item in roleList"
                :key="item.id"
                :label="item"
              >
                {{ item.name }}
              </el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="smsDialog = false">关 闭</el-button>
        <el-button type="primary" @click="sendCommit">发 送</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  saveTime,
  getDateTimeList,
  delDateTime,
  getAllInfoList,
  sendMovieMessage
} from '@/api/movie';
import { getRoleList } from '@/api/app/appRole';

export default {
  name: 'MovieTime',
  data() {
    return {
      pickerOptions: {
        shortcuts: [
          {
            text: '今天',
            onClick(picker) {
              picker.$emit('pick', new Date());
            }
          },
          {
            text: '昨天',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit('pick', date);
            }
          },
          {
            text: '一周前',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', date);
            }
          }
        ]
      },
      form: {
        id: '',
        infoId: '',
        playDate: '',
        playTime: '',
        nums: 0
      },
      query: {
        infoId: '',
        playDate: ''
      },
      pageInfo: {
        startPage: 1,
        pageSize: 20,
        total: 0,
        infoId: '',
        playDate: ''
      },
      movieOptions: [],
      titleName: '电影场次或时间',
      orderTypeList: [],
      list: [],
      listLoading: true,
      dlgLoading: false,
      selectDlgShow: false,
      multipleSelection: [],
      smsLoading: false,
      smsDialog: false,
      checkAll: false,
      roleList: [],
      checkRoleList: [],
      isIndeterminate: true,
      smsForm: {
        ids: [],
        id: 0
      }
    };
  },
  created() {
    this.fetchData();
    this.loadMovieOptions();
  },
  methods: {
    //加载电影信息
    loadMovieOptions() {
      getAllInfoList().then(resp => {
        this.movieOptions = resp.data;
      });
    },
    onPageChange(page) {
      this.pageInfo.startPage = page;
      this.fetchData();
    },
    onSizeChange(size) {
      this.pageInfo.pageSize = size;
      this.fetchData();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    search() {
      this.pageInfo.infoId = this.query.infoId;
      this.pageInfo.playDate = this.query.playDate;
      this.fetchData();
    },
    onRefresh() {
      this.fetchData();
      this.loadMovieOptions();
    },
    //添加
    onAdd() {
      this.form = {
        id: '',
        infoId: '',
        playDate: '',
        playTime: '',
        nums: 0
      };
      this.selectDlgShow = true;
    },
    //编辑
    onEdit(row) {
      this.form = {
        id: row.id,
        infoId: row.infoId,
        playDate: row.playDate,
        playTime: row.playTime,
        nums: row.nums
      };
      this.selectDlgShow = true;
    },
    //查询列表
    fetchData() {
      this.listLoading = true;
      getDateTimeList(this.pageInfo).then(response => {
        this.list = response.data.records;
        this.pageInfo.total = response.data.total;
        this.pageInfo.startPage = response.data.current;
        this.pageInfo.pageSize = response.data.size;
        this.listLoading = false;
      });
    },
    //批量删除
    delAll() {
      if (this.multipleSelection.length == 0) {
        this.$message.warning('请至少选择一条数据');
        return;
      }
      this.$confirm('您确定要删除所选择的数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        var ids = [];
        this.multipleSelection.forEach(item => {
          ids.push(item.id);
        });
        delDateTime({ ids: ids })
          .then(() => {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.fetchData();
          })
          .catch(() => {
            this.$message.error('删除错误！');
          });
      });
    },
    //删除
    onDel(data) {
      this.$confirm('您确定要删除此电影吗, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delDateTime({ id: data.id })
          .then(() => {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.fetchData();
          })
          .catch(() => {
            this.$message.error('删除错误！');
          });
      });
    },
    //验证表单
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.save();
        } else {
          return false;
        }
      });
    },
    //保存与修改
    save() {
      this.dlgLoading = true;
      saveTime(this.form)
        .then(resp => {
          if (resp.code == 200) {
            this.$message({
              message: '保存成功',
              type: 'success'
            });
            this.selectDlgShow = false;
            this.dlgLoading = false;
            this.fetchData();
          } else {
            this.$message.error('保存失败');
          }
        })
        .catch(() => {
          this.dlgLoading = false;
        });
    },
    //加载角色列表
    loadRoleList() {
      getRoleList({}).then(response => {
        this.roleList = response.data.records;
      });
    },
    handleCheckAllChange(val) {
      this.checkRoleList = val ? this.roleList : [];
      this.isIndeterminate = false;
    },
    handleCheckedRoleChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.roleList.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.roleList.length;
    },
    //打开短信通知对话框
    sendMsgDialog(row) {
      this.loadRoleList();
      this.smsForm.id = row.id;
      this.checkRoleList = [];
      this.checkAll = false;
      this.smsDialog = true;
    },
    //发送SMS通知
    sendCommit() {
      this.smsForm.ids = [];
      this.checkRoleList.forEach(item => {
        this.smsForm.ids.push(item.id);
      });

      if (this.smsForm.ids.length === 0) {
        this.$message.warning('请至少选择一个');
        return;
      }

      sendMovieMessage(this.smsForm)
        .then(resp => {
          if (resp.code === 200) {
            this.$message({
              message: '发送成功' + resp.data + '条',
              type: 'success'
            });
          } else {
            this.$message.error('发送失败');
          }
        })
        .catch(() => {
          this.$message.error('Service Is Exception');
        });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  }
};
</script>
<style scoped>
.handle-box {
  margin-bottom: 20px;
}
.del-dialog-cnt {
  font-size: 16px;
  text-align: center;
}
</style>
