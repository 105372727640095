import request from '@/utils/request';

/**
 * 【电影资讯】添加和修改最新电影资讯
 * @param {Object} data
 */
export function saveInfo(data) {
  return request({
    url: '/movie/manager/saveInfo',
    method: 'post',
    data
  });
}

/**
 * 【电影资讯】图片上传
 * @param {Object} data
 */
export function uploadPicInfo(data) {
  return request({
    url: '/movie/manager/uploadPicInfo',
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    data
  });
}

/**
 * 【电影资讯】查询电影资讯列表
 * @param {Object}
 */
export function getInfoList(data) {
  return request({
    url: '/movie/manager/getInfoList',
    method: 'post',
    data
  });
}

/**
 * 【电影资讯】状态修改
 * @param {Object} data
 */
export function updateInfoStatus(data) {
  return request({
    url: '/movie/manager/updateInfoStatus',
    method: 'post',
    data
  });
}

/**
 * 【电影资讯】删除电影
 * @param {Object} data
 */
export function delInfo(data) {
  return request({
    url: '/movie/manager/delInfo',
    method: 'post',
    data
  });
}

/**
 * 【电影场次或时间】添加和修改电影场次或时间
 * @param {Object} data
 */
export function saveTime(data) {
  return request({
    url: '/movie/manager/saveTime',
    method: 'post',
    data
  });
}

/**
 * 【电影场次或时间】查询列表
 * @param {Object} data
 */
export function getDateTimeList(data) {
  return request({
    url: '/movie/manager/getDateTimeList',
    method: 'post',
    data
  });
}

/**
 * 【电影场次或时间】删除场次
 * @param {Object} data
 */
export function delDateTime(data) {
  return request({
    url: '/movie/manager/delDateTime',
    method: 'post',
    data
  });
}

/**
 * 【电影资讯】下拉列表框查询所有
 * @param {Object}
 */
export function getAllInfoList() {
  return request({
    url: '/movie/manager/getAllInfoList',
    method: 'post'
  });
}

/**
 * 【电影预约】管理员查询列表
 */
export function getAdminList(data) {
  return request({
    url: '/movie/appointment/getAdminList',
    method: 'post',
    data
  });
}

/**
 * 删除操作
 */
export function delAppointment(data) {
  return request({
    url: '/movie/appointment/del',
    method: 'post',
    data
  });
}

/**
 * 发送电影播放短信
 */
export function sendMovieMessage(data) {
  return request({
    url: '/movie/manager/sendMovieMessage',
    method: 'post',
    data
  });
}
